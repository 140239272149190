import dayjs from 'dayjs'
import { user_type } from '~/.server/indent/type'
import { constants } from './constants'
import bcrypt from 'bcryptjs';
import utc from 'dayjs/plugin/utc'
import tz from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(tz)

type truckGroupType = {
  primary_group_id: number
  truck_type_ids: number[]
}
type routeType = {
  sAliasId: any
  sourceId: number
  sourceBranchId: number
  sourceName: string
  dAliasId: any
  destinationId: number
  destinationBranchId: number
  destinationName: string
}

export const now = dayjs()

const util = {
  today: now.format('DD-MM-YYYY'),
  currentDate: now.format('YYYY-MM-DD HH:MM'),
  FIFTEENTH_DAY: now.add(15, 'days'),
  WEEK: now.subtract(7, 'day').format('DD-MM-YYYY'),
  MONTH: now.subtract(30, 'day').format('DD-MM-YYYY'),
  THREE_MONTH: now.subtract(90, 'day').format('DD-MM-YYYY'),
  isMobileRegex: new RegExp(
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i,
  ),
  DATE_RANGE_FILTER: {
    TODAY: now.format('YYYY-MM-DD'),
    WEEK: now.subtract(7, 'day').format('YYYY-MM-DD'),
    MONTH: now.subtract(30, 'day').format('YYYY-MM-DD'),
    THREE_MONTH: now.subtract(90, 'day').format('YYYY-MM-DD'),
  },
  now: () => dayjs().format('YYYY-MM-DD HH:MM'),
  nowNonFormat: () => dayjs().format(),
  nowNoTiz: new Date().toISOString().slice(0, 19).replace('T', ' '),
  isExpired(expiryAt: string) {
    const expiredAt = dayjs(expiryAt);
    const today = dayjs();
    let hours = today.diff(expiredAt, 'h');
    return hours > 0
  },
  getExpiryMessage(expiryAt: string) {
    const now = new Date().getTime();
    const expiryDate = new Date(expiryAt).getTime();
    const diffMs = expiryDate - now;

    if (diffMs <= 0) {
      return "Expired";
    }

    const diffSeconds = Math.floor(diffMs / 1000);
    const diffMinutes = Math.floor(diffSeconds / 60);
    const diffHours = Math.floor(diffMinutes / 60);
    const diffDays = Math.floor(diffHours / 24);

    if (diffDays > 0) {
      return `Expire in ${diffDays}d`;
    } else if (diffHours > 0) {
      return `Expire in ${diffHours}h`;
    } else if (diffMinutes > 0) {
      return `Expire in ${diffMinutes}min`;
    } else {
      return `Expire in 1 min`;
    }
  },
  getParam: (key: any, search: string, form: any): string => {
    const value = form.getValues(key) || null
    return `${search || ''}${search && value ? '&' : value ? '?' : ''}${value ? 'selected=' + value : ''}`
  },
  getSourceIdparam: (search: string) => {
    const splitSearch = search ? search.split('&') : []
    const param = search.includes('source_id') ? splitSearch[0] : ''
    return param
  },
  getDestinationIdparam: (search: string) => {
    const splitSearch = search ? search.split('&') : []
    const param = search.includes('destination_id') ? splitSearch[0] : ''
    return param
  },
  removeSearchParam: (search: string) => {
    const splitSearch = search ? search.split('&') : []
    const param = splitSearch.filter((w) => !w.startsWith('search')).join('&')
    return param
  },
  removeOffsetParam: (search: string) => {
    const splitSearch = search ? search.split('&') : []
    const param = splitSearch.filter((w) => !w.startsWith('offset')).join('&')
    return param
  },
  getNameAndMobile: (name: string | null, mobile: string): string => {
    const modifiedName = name ? `${name} - ${mobile}` : mobile
    return modifiedName
  },
  composeRatesParam: (route: routeType, truckGroup: truckGroupType, offset: number) => {
    return `?s_id=${route.sourceId}&b_id=${route.sourceBranchId}&d_id=${route.destinationId}&db_id=${route.destinationBranchId}&tt_id=${truckGroup.truck_type_ids}&tg_id=${truckGroup.primary_group_id}&offset=${offset}`
  },
  validate_pan_format: (pan_number: string) => {
    const pan = pan_number ? pan_number.replace(/\s/g, '') : ''
    const regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/
    return pan.length === 10 ? regpan.test(pan) : false
  },
  validate_mobile: (mobile: string) => {
    const length = mobile ? mobile.replace(/\s/g, '').length : ''
    if (length !== 10) {
      throw new Error(`Invalid Mobile Number ${mobile}`)
    }
  },
  validate_special_characters: (name: string) => {
    const format = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/
    const matched_text = format.test(name)
    if (matched_text) {
      throw new Error(`Company name should not contain special characters ${name}`)
    }
  },
  validateUpi: (upiId: string): boolean => {
    const regex = /[a-zA-Z0-9_]{3,}@[a-zA-Z]{2,}/
    return regex.test(upiId)
  },
  callNow: (mobileNo: string, country_code: string | null = '971') => {
    // const regexPattern = /^(\+?91)?(\d{10})$/
    // if (mobileNo && mobileNo.startsWith('0')) {
    //   mobileNo = mobileNo.slice(1)
    // }
    // if (regexPattern.test(mobileNo)) {
    //   const formattedMobileNo = '+91' + mobileNo.replace(/^(\+?91)?(\d{10})$/, '$2')
    window.location.href = 'tel:' + `+${country_code}${mobileNo}`

  },
  // Function to parse cookie string and retrieve the value of a cookie by its name
  getCookie(cookieString: string, name: string) {
    const cookies = cookieString.split(';').map((cookie) => cookie.trim())
    const cookieObj: { [key: string]: any } = {} // Define the type of cookieObj
    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.split('=')

      if (cookieName === name) {
        try {
          cookieObj[name] = JSON.parse(decodeURIComponent(cookieValue))
        } catch (error) {
          cookieObj[name] = decodeURIComponent(cookieValue)
        }
      }
    }
    return cookieObj[name]
  },
  getLatLng: (location: any) => {
    const splitLocation = location.length > 0 ? location?.split(',') : ''
    const long = parseFloat(splitLocation[0]?.replace('(', ''))
    const lati = parseFloat(splitLocation[1]?.replace(')', ''))
    return [long, lati]
  },
  getCurrentPageName: (pathname: string, defaultPath: string) => {
    const removeFilter = pathname.split('/').filter((p: string) => p !== 'filter')
    const currentPage =
      removeFilter && removeFilter?.length > 0
        ? removeFilter[removeFilter?.length - 1]
        : defaultPath
    return currentPage
  },
  fetchApi: ({
    url,
    session_variables,
    input,
  }: {
    url: string
    session_variables: any
    input: any
  }) =>
    fetch(url, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ session_variables, input }),
    }),
  /**
   * 
   * @param input (String)
   * @returns only numbers (0-9)
   * @example 
   * removeSpecialCharactersAndSpaces(inputString)
   * Output: "1234567890"
   */
  removeSpecialCharactersAndSpaces: (input: string) => {
    return input ? input?.replace(/[^\d]/g, '') : ""
  },
  getUserType(user: any): user_type | null {
    const { USER_TYPE } = constants;

    if (user?.is_shipper) {
      return USER_TYPE.SHIPPER;
    } else if (user?.is_carrier) {
      return USER_TYPE.CARRIER;
    } else if (user?.is_employee) {
      return USER_TYPE.EMPLOYEE;
    } else {
      return null;
    }
  },
  baseMeta: [
    { charset: 'utf-8' },
    { name: 'viewport', content: 'viewport-fit=auto, width=device-width,initial-scale=1,maximum-scale=1' }
  ],
  getUserHashedPassword: async (password: string | null | undefined) => {
    let hashedPassword = null;

    if (password) {
      const salt = await bcrypt.genSalt(11);
      hashedPassword = await bcrypt.hash(password, salt);
    };

    return hashedPassword;
  },
  checkChanges: (dbIds: number[] = [], uiIds: number[] = []) => {
    // Early return if the arrays are exactly the same
    if (util.arraysEqual(dbIds, uiIds)) {
      return {
        isChanged: false,
        onlyRemoved: false,
        totallyChanged: false,
        onlyAdded: false,
        removedIds: [],
        addedIds: []
      };
    }

    const dbSet = new Set(dbIds);
    const uiSet = new Set(uiIds);

    const removedIds: number[] = dbIds.filter(id => !uiSet.has(id));
    const addedIds = uiIds.filter(id => !dbSet.has(id));

    const onlyRemoved = removedIds.length > 0 && addedIds.length === 0;
    const onlyAdded = addedIds.length > 0 && removedIds?.length === 0;
    const totallyChanged = addedIds.length > 0 && removedIds?.length > 0

    return {
      isChanged: true,
      onlyRemoved,
      onlyAdded,
      totallyChanged,
      removedIds: removedIds || [],
      addedIds
    };
  },
  arraysEqual: (arr1: number[], arr2: number[]) => {
    if (arr1.length !== arr2.length) return false;
    return arr1.every((id, index) => id === arr2[index]);
  },
  isPageChanging: (state: string) => {
    return ['loading', 'submitting'].includes(state)
  },
  getTime() {
    const d = new Date();
    const minutes = Math.round(d.getMinutes() / 15) * 15;
    return { hours: d.getHours(), minutes: minutes === 60 ? 0 : minutes }
  },
  utcTimeString(date: string) {
    return dayjs.utc(date).format('DD-MM-YYYY HH:mm')
  },
  calculateCarrierPrice(shipper_price: number | null | undefined, freight_margin: number | null | undefined): number {
    return (shipper_price ?? 0) - (freight_margin ?? 0);
  },
  convertBase64ToBlob(base64String: string) {
    const base64Data = base64String.split(",")[1];
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    // Create a Blob and a URL from the Blob
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    return url
  },
  getTextAvatar(name: string) {
    // Split the name by spaces to get individual words
    const words = name.trim().split(/\s+/);
    // Map over the words and get the first letter of each word
    const initials = words.slice(0, 2).map(word => word[0].toUpperCase()).join('');
    return initials;
  },
  getView(keyName: string) {
    const view = localStorage.getItem(keyName) ||
      (util.isMobileRegex.test(navigator.userAgent) ? "card" : "table")
    return view
  },
  getCurrentTab(tabConfig: any[], pathname: string, defaultKey: string) {
    const pathSplit = pathname.split("/");
    const endPath = pathSplit[pathSplit?.length - 1];
    const carrierTabKeys = tabConfig.map((tc) => tc.value);
    const currentTab = carrierTabKeys.includes(endPath) ? endPath : defaultKey;
    return currentTab
  },
  sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms))
  },
  findDocumentNameById(id: number) {
    const document_type: Record<string, { id: number; name: string }> = constants.DOCUMENT_TYPE;
    for (let key in document_type) {
      if (document_type[key].id === id) {
        return document_type[key].name;
      }
    }
    return null;
  },
  sumCharges: (...charges: number[]) => charges.reduce((acc: number, curr: number) => acc + +(curr || 0), 0),
  getTAT: (status_at: string | undefined) => {
    const statusAt = status_at
      ? dayjs(status_at)
      : dayjs().subtract(90, "minutes");
    const nowDate = dayjs().subtract(90, "minutes");
    const TAT_hrs = nowDate.diff(statusAt, "hours", true);

    const TAT =
      TAT_hrs < 24 ? `${Math.ceil(TAT_hrs)}h` : `${Math.floor(TAT_hrs / 24)}d`;
    return TAT
  }
}

export default util
